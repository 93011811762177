.post_Add_CategoryButton h5 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #373a3c !important;
}

.post_Add_CategoryButton .headings {
  background: #dedede;
  color: #231f20;
  border-radius: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  padding: 10px;
}

.post_Add_CategoryButton .post_AddForm .post_Add_category .form-check-label {
  color: #343434 !important;
}

.post_Add_CategoryButton:first-child {
  margin-top: 32px;
}

.post_Add_CategoryButton .post_AddForm .selectNew .form-check {
  display: flex !important;
  align-items: end !important;
  justify-content: center !important;
  padding-left: 0px !important;
}

.post_Add_CategoryButton .post_AddForm .form-check {
  display: flex !important;
  align-items: center;
}

.post_Add_CategoryType > div {
  width: 50%;
}

.post_Add_CategoryType {
  margin-bottom: 28px !important;
  margin-top: 26px !important;
  gap: 9px 0px;
  margin-left: 50px;
}

.post_Add_CategoryButton .post_AddForm .post_Add_category .form-check-input {
  height: 26px !important;
  width: 26px !important;
  position: relative !important;
  background: #ffffff;
  border: none !important;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 11%) !important;
  cursor: pointer;
}

.post_Add_CategoryButton
  .post_AddForm
  .post_Add_category
  .form-check-input:focus {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.post_Add_CategoryButton
  .post_AddForm
  .post_Add_category
  .form-check-input:active {
  filter: none !important;
}

.negotiable .negotiables .form-check-input {
  height: 26px !important;
  width: 26px !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%) !important;
  position: relative !important;
  border: none;
}

.negotiable .negotiables [type="checkbox"]:checked,
.negotiable .negotiables [type="checkbox"]:not(:checked) {
  left: 0px !important;
}

.negotiable .negotiables .form-check-input[type="checkbox"]:checked::after {
  content: "";
  width: 26px !important;
  height: 26px !important;
  background: #F05232 !important;
  position: absolute !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.negotiable .negotiables .form-check-input[type="checkbox"]:checked::before {
  content: "";
  left: 10px;
  top: 4px;
  z-index: 99 !important;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
}

.negotiable .negotiables .form-check-input:checked {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.107654);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.post_Add_CategoryButton .post_AddForm .form-check .form-check-label {
  margin-left: 10px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #343434;
  margin-top: 5px;
}

.post_Add_CategoryButton
  .post_AddForm
  .post_Add_category
  .form-check-input[type="radio"]:checked::after {
  content: "";
  width: 18px !important;
  height: 18px !important;
  background: #F05232 !important;
  position: absolute !important;
  border-radius: 50% !important;
}

.post_Add_CategoryButton
  .post_AddForm
  .post_Add_category
  .form-check-input:checked {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.107654);
  display: flex;
  justify-content: center;
  align-items: center;
}

.post_AddForm > div:first-child {
  display: flex;
  flex-wrap: wrap;
}

.post_AddForm > div:first-child > div {
  width: 50%;
}

.post_AddForm .heading {
  background-color: #f6f6f6;
  border: none !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%);
}

.post_AddForm .heading::placeholder {
  color: #999999 !important;
  /* height: 42px !important; */
  font-size: 16px;
  font-weight: 400;
}

.post_AddForm .post_Add_Discription {
  height: 176px;
  border: none !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%);
}

.post_AddForm .post_Add_Discription::placeholder {
  color: #999999;
}

.post_AddForm .post_Add_Locationtype {
  display: flex;
}

.post_AddForm .post_Add_Locationtype > div {
  width: 50%;
}

.post_AddForm .post_Add_EmploymentEnquiry {
  display: flex;
}

.post_AddForm .post_Add_EmploymentEnquiry > div {
  width: 50%;
}

.css-lnmdiq5-menu {
  color: black !important;
}

.post_Add_AddPhoto div {
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  border-radius: 10px;
  min-width: 243px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f76203;
  background-color: #f76203;
  cursor: pointer;
}

.post_Add_AddPhoto {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.post_Add_Save button {
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  border-radius: 10px;
  background-color: #000000;
  height: 46px !important;
}

.post_Add_DeleteAdever button {
  height: 62px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  border-radius: 10px;
  text-align: center;
  border: none;
  width: 100%;
}

.post_Add_DeleteAdever button svg {
  margin-right: 20px;
  height: 31px;
  width: 28px;
}

.post_Add_DeleteAdever {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  align-items: center;
}

.post_AddForm .companyName {
  position: relative;
}

.post_AddForm .companyName .companyName_Control {
  padding-left: 63px;
  position: relative;
}

.post_AddForm .companyName div {
  position: absolute;
  bottom: 11px;
  background: #000000;
  border-top-left-radius: 7.26px;
  border-bottom-left-radius: 7.26px;
  width: 50px;
  margin-bottom: -11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  top: 0px;
}

.post_AddForm .companyName div img {
  position: absolute;
  height: 20px;
  width: 20px;
}

.post_Add_CompanyName {
  margin-top: 16px;
  margin-bottom: 16px;
}

.post_AddForm .names {
  position: relative;
}

.post_AddForm .names .names_Control {
  padding-left: 63px;
  position: relative;
}

.post_AddForm .names div {
  position: absolute;
  bottom: 11px;
  background: #000000;
  border-top-left-radius: 7.26px;
  border-bottom-left-radius: 7.26px;
  width: 50px;
  margin-bottom: -11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  top: 0px;
}

.post_AddForm .names div img {
  position: absolute;
  height: 20px;
  width: 20px;
}

.post_Add_Name {
  margin-top: 16px;
  margin-bottom: 16px;
}

.post_AddForm .companyName {
  position: relative;
}

.post_AddForm .amount {
  position: relative;
}

.post_AddForm .amount div {
  position: absolute;
  bottom: 11px;
  background: #000000;
  border-top-left-radius: 7.26px;
  border-bottom-left-radius: 7.26px;
  width: 50px;
  margin-bottom: -11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  top: 0px;
}

.post_AddForm .amount div p {
  position: absolute;
  top: 5px;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.post_Add_Locationtype {
  margin-top: 16px;
  margin-bottom: 31px;
}

.post_Add_EmploymentEnquiry {
  margin-top: 13px;
  margin-bottom: 29px;
}

.css-13cymwt-control .css-1xc3v61-indicatorContainer {
  color: white !important;
}

.names_Control::placeholder {
  color: #999999 !important;
}

.companyName_Control::placeholder {
  color: #999999 !important;
}

.post_Add_Error {
  height: 51px;
  background: #a8ecdc;
  border-radius: 10px;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #231f20;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.post_Add_priceDiv .btn {
  width: 50px !important;
  margin-top: 0px;
  height: 40px;
  font-weight: 400;
  line-height: 28px;
  color: #ffffff;
  background-color: #000000;
  display: flex;
  justify-content: space-around;
  border-top-left-radius: 7.26px;
  border-bottom-left-radius: 7.26px;
  position: absolute;
}

.post_Add_priceDiv .btn:hover {
  background-color: #000000 !important;
}

.post_Add_priceDiv {
  width: 100% !important;
}

.post_Add_priceDiv .dropdown-toggle::after {
  display: none !important;
}

.post_Add_priceDiv
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  width: 100% !important;
}

.post_Add_priceDiv
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  width: 100% !important;
}

.post_Add_priceDiv input.amount_Control.form-control {
  padding-left: 63px;
}

.css-a0izu-control {
  background-color: black !important;
  color: white !important;
}

.post_Add_Heading_Flex {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.post_Add_Heading_Flex p {
  color: #f05132;
  margin-bottom: 0px !important;
  font-size: 16px;
  font-weight: 400;
}

.Post_Add_ImageSet {
  width: 120px;
  height: 120px;
  position: relative;
  border: 1px solid #e7e4e4;
}

.Post_Add_ImageSet img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 1px solid #e7e4e4;
}

.Post_Add_ImageSet svg {
  top: 5px;
  right: 5px;
  position: absolute;
}

.post_Add_ImagePreview {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.post_Add_ImagePreview > div {
  margin-right: 10px;
  margin-top: 10px;
}

.post_AddForm .form-control {
  border: none !important;
  height: 40px !important;
  background: #ffffff !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%) !important;
  border-radius: 7.26px !important;
}

.post_Add_Delete button {
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 100%;
  align-items: center;
  background: none !important;
}

.post_Add_Delete {
  margin-top: 18px;
}

.post_Add_Delete button svg {
  margin-right: 7px;
  color: #000000;
  font-size: 23px;
  margin-bottom: 4px;
}

.post_Add_Delete .deletePost {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.post_Add_CategoryButton
  .post_AddForm
  .negotiable
  .form-check
  .form-check-label {
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

@media only screen and (min-width: 400px) and (max-width: 575px) {
  .post_Add_CategoryType {
    width: 386px;
    margin: auto;
  }

  .post_Add_CategoryButton .post_AddForm .form-check .form-check-label {
    font-size: 18px;
  }

  .post_Add_CategoryButton .headings {
    font-size: 19px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 399px) {
  .post_Add_CategoryType {
    margin: auto;
  }

  .post_Add_CategoryButton .post_AddForm .form-check .form-check-label {
    font-size: 16px;
  }

  .post_Add_CategoryButton .headings {
    font-size: 19px;
  }
}

/* css for select input */

.selectOptionPost .css-b62m3t-container {
  z-index: 999 !important;
}

.selectOptionPost .css-a0izu-control:hover {
  border-color: unset !important;
}

.selectOptionPost .css-a0izu-control {
  border: none !important;
  background: #000000 !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%) !important;
  border-radius: 7.26px !important;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
}

.selectOptionPost .css-13cymwt-control {
  border: none !important;
  background: #000000 !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%) !important;
  border-radius: 7.26px !important;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
}

.selectOptionPost .css-13cymwt-control:hover {
  border-color: unset !important;
}

.selectOptionPost .css-1xz3in2-control {
  border: none !important;
  background: #000000 !important;
  box-shadow: 2.42px 4.84px 12.1px rgb(0 0 0 / 6%) !important;
  border-radius: 7.26px !important;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
}

.selectOptionPost .css-1xz3in2-control:hover {
  border-color: unset !important;
}

.selectOptionPost .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.selectOptionPost .css-tj5bde-Svg {
  fill: #ffffff !important;
  width: 28px !important;
  height: 28px !important;
}

.selectOptionPost .css-8mmkcg {
  fill: #ffffff !important;
  width: 28px !important;
  height: 28px !important;
}

.post_AddForm #location .css-1dimb5e-singleValue {
  color: white !important;
}

.post_AddForm #jobtype .css-1dimb5e-singleValue {
  color: white !important;
}

.post_AddForm #earningoption .css-1dimb5e-singleValue {
  color: white !important;
}

.post_AddForm #country .css-13cymwt-control {
  color: black !important;
}

.post_AddForm #country .css-t3ipsp-control {
  color: black !important;
}

.post_AddForm #province .css-13cymwt-control {
  color: black !important;
}

.post_AddForm #province .css-t3ipsp-control {
  color: black !important;
}

.post_AddForm #cities .css-13cymwt-control {
  color: black !important;
}

.selectOption .css-1onb4m5-Placeholder {
  color: #231f20;
  position: absolute;
  left: 10px !important;
}

.post_AddForm #cities .css-t3ipsp-control {
  color: black !important;
}

.dropdown_menu .dropdown-menu.show {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

.dropdown_menu .dropdown-item.active,
.dropdown-item:active {
  background-color: black !important;
}

@media only screen and (max-width: 400px) {
  .selectOptionPost .css-a0izu-control {
    font-size: 18px;
  }

  .selectOptionPost .css-13cymwt-control {
    font-size: 18px;
  }

  .selectOptionPost .css-1xz3in2-control {
    font-size: 18px;
  }

  .post_Add_priceDiv .dropdown_menu .dropdown-menu.show input {
    width: 250px !important;
  }

  .dropdown_menu .dropdown-menu.show {
    width: 300px !important;
  }
}

@media only screen and (max-width: 400px) {
  .post_Add_AddPhoto div {
    min-width: 203.03px;
  }
}

@media only screen and (max-width: 991px) {
  .post_Add_CategoryButton:first-child {
    margin-top: 15px;
  }
}

.post_AddForm .customContact .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding-left: 10px !important;
}

.post_Add_CategoryButton .customPhoneDropDown p {
  margin-top: 14px;
}

.post_Add_CategoryButton .customPhoneDropDown {
  top: -5px;
}
