.classiFieds_forSaleBox {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  margin-bottom: 25px;
  border-radius: 6px;
}

.classiFieds_forSaleBox .classiFieds_forSale_about {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.classiFields_mobileMargingRemove {
  margin-bottom: 0px !important;
}

.classiFieds_forSale_about .classiFieds_forSale {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.classiFieds_forSale_about .classiFieds_forSale img {
  width: 180px;
  height: 135px;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
  margin-right: 15px;
  object-fit: cover;
}

.classiFieds_forSale_about .classiFields_heading .text {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 0px;
}

.classiFieds_forSale_about .classiFields_heading {
  width: 100%;
}

.classiFieds_forSale_about .classiFieds_time_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.classiFieds_forSale_about
  .classiFieds_time_action
  .classiFieds_forSale_button {
  border-radius: 14px;
  line-height: 20px;
  border: none !important;
  color: white;
  font-weight: 600;
  height: 27.89px;
  width: 97.63px;
  font-size: 12px;
}

.classiFieds_forSaleBox .classiFields_contactPerson {
  background: #e8e8e8;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.0311407);
  border-radius: 6px;
  padding: 20px;
}

.classiFieds_forSaleBox .heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 15px;
}

.classiFields_contactPerson .classiFields_PersonAboutShow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px !important;
  cursor: pointer;
}

.classiFields_PersonAboutShow p {
  margin-bottom: 0px;
}

.classiFields_iconBackGround {
  height: 40px;
  width: 40px;
  background: #e5e5e5;
  mix-blend-mode: multiply;
  border-radius: 6px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.classiFields_PersonAboutShow img {
  height: 40px;
  width: 40px;
}

.classiFieds_forSaledropdown button {
  background: #F05232;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.classiFieds_wanteddropdown button {
  background: #dfdfdf;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: black;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.classiFieds_forSale p {
  color: white;
}

/* .classiFieds_time span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #000000;
  margin-left: 7px;
} */

.classiFieds_time {
  display: flex;
  align-items: center;
}

/* .classiFieds_time span img {
  height: 18.57px;
  margin-right: 6px;
  width: 18.57px;
  vertical-align: sub;
} */

.classiFieds_watch {
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.classiFieds_watch img {
  height: 18.57px;
  margin-right: 6px;
  width: 18.57px;
}

.classiFieds_watch p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 2px;
}

.classiFieds_RupeesText p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.2px;
  color: #e91f63;
  margin-bottom: 0px;
}

.classiFieds_RupeesText span {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.classiFieds_countryName span {
  font-weight: 600;
  font-size: 15px;
  line-height: 36px;
  letter-spacing: -0.48px;
  color: #e91f63;
  font-family: "Nunito Sans";
}

.classiFieds_countryName img {
  width: 20px;
  margin-bottom: 7px;
  margin-right: 10px;
}

.classiFieds_aboutText p {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.3px;
  margin-top: 10px;
  font-family: "Nunito Sans";
}

.classiFields_whatAppIcon {
  width: 100%;
  background: linear-gradient(180deg, #85d51d 0%, #6aae1b 98.67%);
  border-radius: 6px;
  height: 40px;
  border: none;
  margin-top: 15px;
}

.classiFields_whatAppIcon svg {
  position: static !important;
  width: 28px;
  height: 28px;
  margin-top: 5px;
}

.classiFields_shareMedia {
  height: 66px;
  background: #ebebeb;
  border-radius: 6px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.classiFields_shareicons img {
  width: 48px;
  height: 48px;
  margin-left: 5px;
}

.classiFields_shareIcon p {
  margin-bottom: -10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-left: -6px;
}

.classiFields_shareIcon {
  margin-left: 20px;
}

.classiFields_shareicons span > span {
  margin-right: 5px;
}

.classiFields_shareIcon img {
  width: 4px;
  height: 4px;
  margin: 2px;
  border-radius: 50%;
}

.classiFields_advertise {
  width: 339px;
  height: 277px;
  margin-bottom: 15px;
}

.classiFields_advertise img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.classiFields_whatAppIcon span {
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 19.2px !important;
  line-height: 40px !important;
  display: flex;
  justify-content: center;
  margin-left: 6px;
}

.classiFieds_forSaledropdown {
  margin-bottom: 15px;
}

.advertisment {
  display: flex;
  flex-direction: column;
}

.classiFieds_map_serchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classiFieds_country_select {
  max-height: 200px;
  overflow-y: scroll;
  z-index: 99999999999 !important;
  position: absolute;
  background: #F05232;
}

.classiFieds_country_select > div {
  border: 1px solid #F05232;
  color: white;
}

.categoryButton .nav-item {
  position: relative;
}

.categoryButton svg {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 30px;
}

.classiFields_iconBackGround svg {
  font-size: 22px;
  margin-left: 11px;
  margin-top: 10px;
}

.nodataDisplay {
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.315px;
  color: #000000;
}

.jobType p {
  color: #e91f63;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

/* .advertisment iframe {
  width: 100%;
  height: 140px;
  width: 1px;
  min-width: 100%;
  width: 100%;
  height: 100%;
  border: 0;
  scroll-behavior: unset;
} */

.classiFields_emailHeadingText a {
  text-decoration: none;
  color: #000000;
}

.classiFields_emailHeadingText p {
  color: #787878;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

.classiFields_emailHeadingText span {
  font-weight: 400;
  font-size: 16.5px;
  line-break: anywhere;
  line-height: 25px;
  letter-spacing: -0.515625px;
}

.classiFieds_countryName {
  margin-top: 10px;
}

.classiFieds_bookmarkicon svg {
  color: #000000;
  position: absolute;
  right: -5px;
  top: 0;
}

@media only screen and (max-width: 383px) {
  .classiFields_shareicons img {
    width: 32.12px;
    height: 32.12px;
    margin-left: 2px;
  }

  .classiFieds_countryName span {
    font-size: 12px;
  }

  .classiFields_emailHeadingText span {
    font-size: 13.5px;
  }

  .classiFields_shareIcon {
    margin-top: 4px;
  }
}

@media only screen and (min-width: 381px) and (max-width: 390px) {
  .classiFields_emailHeadingText span {
    font-size: 15.5px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 340px) {
  .classiFields_emailHeadingText span {
    font-size: 11.5px;
  }
}

@media only screen and (min-width: 422px) and (max-width: 480px) {
  .classiFieds_forSale_about .classiFieds_forSale img {
    width: 116.83px;
    height: 110.62px;
  }

  .classiFieds_forSale_about
    .classiFieds_time_action
    .classiFieds_forSale_button {
    width: 89.63px;
    font-size: 10.4378px !important;
    height: 25.89px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 421px) {
  .classiFieds_forSale_about .classiFieds_forSale img {
    width: 100.83px;
    height: 95.62px;
  }

  .classiFieds_forSale_about
    .classiFieds_time_action
    .classiFieds_forSale_button {
    width: 74.63px;
    font-size: 10.4378px !important;
    height: 25.89px;
  }

  .classiFieds_forSale_about .classiFields_heading .text {
    font-size: 16.0467px;
    line-height: 17px;
  }

  .classiFieds_watch img {
    height: 14.57px;
    width: 14.57px;
  }

  .classiFieds_watch p {
    font-size: 10.78876px;
    line-height: 12px;
    margin-top: 2px;
  }
}

@media only screen and (min-width: 340px) and (max-width: 360px) {
  .classiFieds_bookmarkicon svg {
    color: #000000;
    position: absolute;
    right: 0;
    top: -1;
    width: 22px;
  }

  .classiFieds_forSale_about .classiFieds_forSale img {
    width: 87.83px;
    height: 84.62px;
    margin-right: 10px;
  }

  .classiFieds_forSale_about
    .classiFieds_time_action
    .classiFieds_forSale_button {
    width: 74.63px;
    font-size: 10.4378px !important;
    height: 25.89px;
  }

  .classiFieds_forSale_about .classiFields_heading .text {
    font-size: 15.0467px;
    line-height: 17px;
  }

  .classiFieds_watch img {
    height: 14.57px;
    width: 14.57px;
  }

  .classiFieds_watch p {
    font-size: 10.78876px;
    line-height: 12px;
    margin-top: 2px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 339px) {
  .classiFieds_bookmarkicon svg {
    color: #000000;
    position: absolute;
    right: 0;
    top: -3px;
    width: 22px;
  }

  .classiFieds_forSale_about .classiFieds_forSale img {
    width: 87.83px;
    height: 84.62px;
    margin-right: 10px;
  }

  .classiFieds_forSale_about .classiFields_heading .text {
    font-size: 14.0467px;
    line-height: 17px;
  }

  .classiFieds_forSale_about
    .classiFieds_time_action
    .classiFieds_forSale_button {
    width: 62.63px;
    font-size: 8.4378px !important;
    height: 21.89px;
  }

  .classiFieds_watch img {
    height: 14.57px;
    width: 14.57px;
    margin-right: 3px;
  }

  .classiFieds_watch p {
    font-size: 9.78876px;
    line-height: 12px;
    margin-top: 1px;
  }
}

.loadmoreBtn button {
  background: #F05232 !important;
}

.loadmoreBtn button:hover {
  background-color: #F05232 !important;
}

.loadmoreBtn button:active {
  background: #F05232 !important;
}

/* .advertisment iframe {
  overflow: hidden;
}  */

.inputBox .css-tj5bde-Svg {
  fill: #000000 !important;
}

.inputBox .css-8mmkcg {
  fill: #000000 !important;
}

.inputBox .form-control:focus {
  border-color: #ced4da !important;
  outline: 0;
  border-radius: 4px !important;
  box-shadow: unset !important;
}

.inputBox .form-control {
  border-radius: 4px !important;
}

.inputBox .css-13cymwt-control {
  border-color: #ced4da !important;
  box-shadow: unset !important;
}

.inputBox .css-13cymwt-control:hover {
  border-color: #ced4da !important;
}

.inputBox .css-1xz3in2-control {
  border-color: #ced4da !important;
  box-shadow: unset !important;
}

@media screen and (min-width: 320px) and (max-width: 330px) {
  @-moz-document url-prefix() {
    .classiFieds_countryName span {
      font-size: 11px;
    }

    .classiFieds_countryName img {
      margin-right: 7px;
    }
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .classiFieds_forSale_about
    .classiFieds_time_action
    .classiFieds_forSale_button {
    width: 78.63px;
    font-size: 8.4378px !important;
    height: 25.89px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .classiFieds_forSale_about
    .classiFieds_time_action
    .classiFieds_forSale_button {
    width: 78.63px;
    font-size: 8.4378px !important;
    height: 28.89px;
  }
}

.classified_tag {
  text-decoration: none !important;
}

.imgPosition {
  position: relative;
  cursor: pointer;
}

.svgIcon {
  position: absolute;
  color: white;
  left: 6px;
  bottom: 0px;
}

.svgIcon img {
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 3px !important;
  margin-right: 5px !important;
  box-shadow: none !important;
  /* box-shadow: 0px 2px 5px #ccc; */
  /* text-shadow: 1px 1px 2px black; */
  /* filter: drop-shadow(-10px -10px 11px black) !important; */
}

.svgIcon span {
  font-size: 16px;
  font-weight: bolder;
  text-shadow: 1px 1px 2px black;
}
