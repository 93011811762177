.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #F05232 !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 13px;
  height: 50px;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  background: #dfdfdf !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 13px;
  height: 50px;
}

.addTabs svg {
  font-weight: 700;
  font-size: 25px;
  position: absolute;
  right: 5px;
}

.collapsed .showIcon {
  display: none;
}

.hideIcon {
  display: none;
}

.collapsed .hideIcon {
  display: block;
}

.alertBody .modal-dialog {
  max-width: 342px !important;
  height: 302px;
  transform: translate(0, 140px) !important;
}

.inputBox .css-b62m3t-container {
  width: 100%;
  min-width: 300px;
}

@media screen and (min-width: 360px) and (max-width: 575px) {
  .alertBody .modal-dialog {
    max-width: 342px !important;
    height: 302px;
    margin: auto;
    margin-top: 28px;
  }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  .inputBox .css-b62m3t-container {
    width: 100%;
    min-width: unset !important;
  }
}

@media screen and (min-width: 310px) and (max-width: 341px) {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 13px;
  }

  .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    font-size: 13px;
  }
}

@media screen and (min-width: 342px) and (max-width: 370px) {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 14px;
  }

  .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    font-size: 14px;
  }
}

@media screen and (min-width: 371px) and (max-width: 400px) {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 15px;
  }

  .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    font-size: 15px;
  }
}

.accordionResponsive .accordion-body {
  padding: 10px 0px;
}

.accordionResponsive .accordion-item {
  color: unset !important;
  background-color: unset !important;
  border: unset !important;
}

.accordionResponsive .accordion-button {
  background: #dfdfdf !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  font-size: 16px;
  color: #000000 !important;
  margin-top: 13px;
  height: 50px;
  border-radius: 6px;
}

.accordionResponsive .accordion-button:not(.collapsed) {
  background: #F05232 !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.03);
  color: #ffffff !important;
  margin-top: 13px;
  font-weight: 500;
  font-size: 16px;
  height: 50px;
  border-radius: 6px;
}

.accordionResponsive .accordion-button:not(.collapsed)::after {
  background-image: url("../images/down-arrow_up.svg") !important;
  margin-top: -5px !important;
  background-size: 16px !important;
}

/* .accordionResponsive .accordion-button::after {
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */

.accordionResponsive .accordion-button::after {
  background-image: url("../images/down-arrow.png") !important;
  margin-top: 5px !important;
  background-size: 16px !important;
}

@media screen and (min-width: 310px) and (max-width: 341px) {
  .accordionResponsive .accordion-button:not(.collapsed) {
    font-size: 13px;
    padding: 0px 7px;
  }

  .accordionResponsive .accordion-button {
    font-size: 13px;
    padding: 0px 7px;
  }
}

@media screen and (min-width: 342px) and (max-width: 370px) {
  .accordionResponsive .accordion-button:not(.collapsed) {
    font-size: 14px;
    padding: 0px 14px 0px 7px;
  }

  .accordionResponsive .accordion-button {
    font-size: 14px;
    padding: 0px 14px 0px 7px;
  }
}

@media screen and (min-width: 371px) and (max-width: 400px) {
  .accordionResponsive .accordion-button:not(.collapsed) {
    font-size: 14px;
    padding: 0px 16px 0px 12px;
  }

  .accordionResponsive .accordion-button {
    font-size: 14px;
    padding: 0px 16px 0px 12px;
  }
}

.customContact {
  position: absolute;
  top: 0;
  left: 111px;
  width: calc(100% - 111px) !important;
}

.customContact .form-control {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 10px !important;
}

.phoneInputSet .react-tel-input .country-list {
  z-index: 999 !important;
}

.phoneInputSet.watsappInput .react-tel-input .country-list {
  z-index: 9999 !important;
}

.phoneInputSet .react-tel-input .flag-dropdown.open {
  z-index: 9;
}

.phoneInputSet.watsappInput .react-tel-input .flag-dropdown.open {
  z-index: 1;
}

.react-tel-input .form-control {
  visibility: hidden;
}
