.signupForm .form-control {
    background-color: #f6f6f6;
    border: none;
    height: 40px;
}

.signupForm .form-control:focus {
    background-color: #f5f5f5;
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.signupForm .form-control::placeholder {
    color: #656565;
    font-size: 15px;
    font-weight: 400;
}

.passwordinput {
    position: relative;
}

.passwordicon svg {
    font-size: 18px;
    position: absolute;
    bottom: 11px;
    right: 7px;
    color: #9c9ca6b3;
    cursor: pointer;
}

.forgotCls {
    display: flex;
    justify-content: space-between;
}

.forgotCls h6 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    margin-bottom: 0px;
    margin-top: 5px;
    cursor: pointer;
}


/* checkbox custom css */
.customCheck [type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.customCheck [type="checkbox"]:checked+label,
.customCheck [type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    line-height: 25px;
    display: inline-block;
    color: #666;
    padding-right: 20px;
}

.customCheck [type="checkbox"]:checked+label:before,
.customCheck [type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #ced9e3;
    border-radius: 100%;
    background: #fff;
}

.customCheck [type="checkbox"]:checked+label:after,
.customCheck [type="checkbox"]:not(:checked)+label:after {
    content: '';
    width: 17px;
    height: 17px;
    background: #EC4624;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.customCheck [type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.customCheck [type="checkbox"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.customCheck label {
    font-weight: 400;
    font-size: 14px;
    color: #000000 !important;
    margin-left: -22px;
}

/* checkbox custom css end */

.errorSet {
    margin-top: 20px;
}

.errorShow {
    color: #D62D2D;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.LoginText {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 15px;
}

.LoginText span {
    opacity: 0.2;
    background: #FFFFFF;
    border-radius: 2px;
    width: 85px;
    margin-bottom: 10px;
    border-bottom: 1px solid #2B2C43;
}

.LoginText p {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #2B2C43;
    opacity: 0.6;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.socialLogo {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-left: -11px;
    margin-right: -11px;
}

.socialLogo img {
    width: 100%;
    max-width: 76px;
    min-width: 30px;
    cursor: pointer;
}

@media screen and(min-width: 320px) and (max-width: 452px) {
    .passwordicon svg {
        position: absolute;
        left: 143px;
    }
}

@media screen and (min-width: 368px) and (max-width: 387px) {
    .forgotCls h6 {
        font-size: 13px !important;
    }

    .customCheck label {
        font-size: 13px;
    }

    .customCheck [type="checkbox"]:checked+label,
    .customCheck [type="checkbox"]:not(:checked)+label {
        padding-left: 28px;
    }
}

@media screen and (min-width: 356px) and (max-width: 367px) {
    .forgotCls h6 {
        margin-top: 1px;
        margin-left: 48px;
        line-height: 21px;
    }

    .customCheck [type="checkbox"]:checked+label,
    .customCheck [type="checkbox"]:not(:checked)+label {
        line-height: unset;
    }
}

@media screen and (min-width: 345px) and (max-width: 355px) {
    .forgotCls h6 {
        margin-top: 1px;
        margin-left: 34px;
        line-height: 21px;
    }

    .customCheck [type="checkbox"]:checked+label,
    .customCheck [type="checkbox"]:not(:checked)+label {
        line-height: unset;
    }
}

@media screen and (min-width: 330px) and (max-width: 344px) {
    .forgotCls h6 {
        margin-top: 1px;
        margin-left: 22px;
        line-height: 21px;
    }

    .customCheck [type="checkbox"]:checked+label,
    .customCheck [type="checkbox"]:not(:checked)+label {
        line-height: unset;
    }
}

@media screen and (min-width: 320px) and (max-width: 329px) {
    .forgotCls h6 {
        margin-top: 1px;
        margin-left: 9px;
        line-height: 21px;
    }

    .customCheck [type="checkbox"]:checked+label,
    .customCheck [type="checkbox"]:not(:checked)+label {
        line-height: unset;
    }
}

@media screen and (min-width: 320px) and (max-width: 378px) {
    .LoginText span {
        width: 55px;
    }
}