.signupForm .form-control::placeholder {
  color: #656565;
  font-size: 14px;
  font-weight: 400;
}

 
 
 
 .notification svg {
  font-size: 41px;
    color: #F05232;
    margin-left: 15px;
}

.notification {
  display: flex;
  justify-content: space-evenly;
}

.notification p {
  font-size: 17px;
  color: #000000;
  font-weight: 400;
  margin-top: 8px;
}

@media screen and (max-width: 477px) {
  .notification svg {
    font-size: 43px;
    color: #F05232;
    margin-left: 12px;
  }
  .notification p {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    margin-top: 11px;
  }
}

.toast-message{
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}


